/* eslint-disable react/prop-types */
import React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import { useRouter, } from 'next/router';
import A11yDialog from '../A11yDialog/A11yDialog';
import Button from '../Button/Button';
import ServiceByMailRegistration from './ServiceByMailRegistration';
import authorPropTypes from '../../propTypes/authorPropTypes';
import IconClose from '../Icon/icons/IconClose';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import useArticleId from '../../hooks/Page/useArticleId';

export default function AuthorNotificationsRegistration({
  biAction,
  author,
  onToggle,
  origin,
  isBlog,
}) {
  const [ showDialog, setShowDialog, ] = React.useState(false);
  const [ registrationStatus, setRegistrationStatus, ] = React.useState(null);
  const [ id, setRandomId, ] = React.useState(null);
  const { css, theme, } = useFela();
  const router = useRouter();

  const breadclumbs = useBreadcrumbsData();

  const articleId = useArticleId();

  const isBlogSection = !articleId && router.asPath.startsWith('/blogs');
  const contentName = (isBlog && breadclumbs?.[isBlogSection ? 0 : 1]?.name) || author?.contentName;

  React.useEffect(() => {
    setRandomId(`authorAlerts_${Math.floor(Math.random() * 100)}`);
  }, []);

  function onRegistrationSuccess({ email, status, }) {
    setShowDialog(true);
    setRegistrationStatus(status);
    biAction({
      actionCode: 9,
      featureType: 'Content',
      feature: `${isBlog ? 'Blog' : 'Writer'} Alert - article page`,
      campaignName: `${isBlog ? 'blog' : 'writer'} name`,
      campaignDetails: theme.serviceByMailI18n.campaignName,
      segmentId: author && author.contentId,
      newsletterEmail: email,
      segmentName: contentName,
    });
  }

  function onRegistrationError(data) {
    setShowDialog(true);
    setRegistrationStatus(null);
  }
  if (!id) {
    return null;
  }

  const buttonI18n = theme.serviceByMailI18n.confirmationButton[registrationStatus]
    || theme.serviceByMailI18n.confirmationButton.default;

  return (
    <div
      className={css({
        fontFamily: theme.fontStacks[theme.serviceByMailStyle.fontStack],
        color: theme.color('neutral', '-1'),
      })}
    >
      <div id={`${id}_form`}>
        <ServiceByMailRegistration
          title={`${theme.serviceByMailI18n.authorAlertServiceTitle}${contentName}`}
          resourceType={isBlog ? 'blog' : 'writer'}
          resourceId={author.contentId}
          resourceName={contentName}
          origin={origin}
          onRegistrationSuccess={onRegistrationSuccess}
          onRegistrationError={onRegistrationError}
          onCancel={onToggle}
          type="user"
        />
      </div>
      <A11yDialog
        appendTo={id}
        elementToHide={`${id}_form`}
        isVisible={showDialog}
        overlayBgColor="rgba(0,0,0,0)"
        onClose={() => {
          onToggle();
          setShowDialog(false);
        }}
        containerMiscStyles={{
          width: '100%',
          height: '100%',
        }}
        render={({ isVisible, handleClose, isModal, }) => (
          <div
            data-test="authorNotificationRegistrationResultDialog"
            className={css({
              height: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              paddingInlineStart: '2rem',
              paddingInlineEnd: '2rem',
              backgroundColor: theme.color('alerts', 'bg'),
            })}
          >
            <button
              onClick={() => {
                onToggle();
              }}
              type="button"
              className={css({
                top: '0rem',
                end: '0rem',
                position: 'absolute',
                extend: [ theme.mq({ until: 'l', }, { top: '1rem', end: '1rem', }), ],
              })}
            >
              <IconClose size={3} />
            </button>
            <div
              className={css({
                fontWeight: '700',
                marginBottom: '2rem',
                extend: [
                  theme.type(-1),
                ],
              })}
            >
              {[ 'success', 'exist', 'pending', ].includes(registrationStatus) ? (
                <>
                  {theme.serviceByMailI18n.confirmationMessageAuthor[registrationStatus](contentName)}
                  {theme.serviceByMailI18n.confirmationMessageSecondRow[registrationStatus] && (
                    <span className={css({ display: 'block', marginTop: '1rem', })}>
                      {theme.serviceByMailI18n.confirmationMessageSecondRow[registrationStatus]}
                    </span>
                  )}
                </>
              ) : (
                theme.serviceByMailI18n.registrationfailureDefaultMessage
              )}
            </div>
            {buttonI18n ? (
              <Button
                href={buttonI18n.href}
                onClick={handleClose}
                variant="neutral"
                miscStyles={{
                  type: -2,
                }}
              >
                {buttonI18n.text}
              </Button>
            ) : null}
          </div>
        )}
      />
      <div id={id} />
    </div>
  );
}

AuthorNotificationsRegistration.propTypes = {
  author: PropTypes.shape(authorPropTypes).isRequired,
  /** Callback to execute when cancel button is pressed, If omitted, the Cancel button wont appear * */
  onToggle: PropTypes.func,
  isBlog: PropTypes.bool.isRequired,
};

AuthorNotificationsRegistration.defaultProps = {
  onToggle: null,
};
