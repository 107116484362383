/* global document */
import React, { useCallback, useState, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { useRouter, } from 'next/router';
import AlertsButton, { alertButtonPropTypes, alertsButtonDefaultProps, } from './AlertsButton';
import IconAlert from '../Icon/icons/IconAlert';
import A11yDialog from '../A11yDialog/A11yDialog';
import AuthorNotificationsRegistration from '../ServiceByMailRegistration/AuthorNotificationsRegistration';
import useMedia from '../../hooks/useMedia';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import useArticleId from '../../hooks/Page/useArticleId';

function buttonStyle({ theme, miscStyles, status, }) {
  const color = `${theme.color('alerts', `openButtonText${status}`)} !important`;

  return {
    color: theme.color('alerts', `openButtonText${status}`),
    fontWeight: 'bold',
    '&:visited': {
      color,
    },
    '&:active': {
      color,
    },
    '&:focus': {
      color,
    },
    '&:hover': {
      color,
    },
    extend: [
      theme.type(-2, { fromBp: 'xl', }),
      theme.type(-1, { fromBp: 'l', untilBp: 'xl', }),
      theme.type(-2, { untilBp: 'l', }),
      ...(miscStyles
        ? parseStyleProps(
          typeof miscStyles === 'function' ? miscStyles({ status, }) : miscStyles,
          theme.mq,
          theme.type
        )
        : []),
    ],
  };
}

function AuthorAlert({
  author,
  onToggle,
  innerRef,
  platform,
  biAction,
  origin,
  modalVariationMq,
  isModal,
  iconSize,
  miscStyles,
  iconMiscStyles,
  isBlog,
}) {
  const [ isShowAuthorAlertsForm, setIsShowAuthorAlertsForm, ] = React.useState(false);
  const shouldOpenAsModal = useMedia({ query: modalVariationMq || '(max-width: 0px)', });
  const isWebView = useWebViewChecker();
  const [ status, setStatus, ] = useState(isWebView ? 'Unfollow' : '');
  const { css, theme, } = useFela({ miscStyles, iconMiscStyles, status, });
  const breadclumbs = useBreadcrumbsData();
  const router = useRouter();
  const articleId = useArticleId();

  const isBlogSection = !articleId && router.asPath.startsWith('/blogs');

  const contentName = (isBlog && breadclumbs?.[isBlogSection ? 0 : 1]?.name) || author?.contentName;

  const onFollow = useCallback(() => {
    if (status !== 'Unfollow') {
      setStatus('Unfollow');
    }
  }, [ status, ]);

  const onUnfollow = useCallback(() => {
    if (status !== 'Follow') {
      setStatus('Follow');
    }
  }, [ status, ]);

  useDocumentEventListener('setFollow', onFollow, false);
  useDocumentEventListener('setUnfollow', onUnfollow, false);

  const isFollow = status === 'Follow';

  if (isWebView) {
    return (
      <a
        href={`https://author.alerts.${isFollow ? 'off' : 'on'}/`}
        target="_blank"
        rel="noreferrer"
        className={css(buttonStyle)}
      >
        <IconAlert
          color={isFollow ? [ 'neutral', '-5', ] : undefined}
          size={iconSize}
          miscStyles={iconMiscStyles}
        />
        <span>
          {' '}
          {theme.alertsI18n[`mobileAlertsText${status}`]}
        </span>
      </a>
    );
  }

  const toggleAuthorAlertsForm = () => {
    setIsShowAuthorAlertsForm(prevState => {
      const newIsShowAuthorAlertsForm = !prevState;

      if (!newIsShowAuthorAlertsForm) {
        innerRef.current && innerRef.current.focus();
      }
      else if (typeof biAction === 'function') {
        biAction({
          actionCode: 91,
          featureType: 'Content',
          feature: `${isBlog ? 'Blog' : 'Writer'} Alert - article page`,
          campaignName: `${isBlog ? 'blog' : 'writer'} name`,
          campaignDetails: theme.alertsI18n.campainName,
          segmentId: author && author.contentId,
          segmentName: contentName,
        });
      }

      return newIsShowAuthorAlertsForm;
    });
  };

  const closeAuthorAlertsForm = () => {
    setIsShowAuthorAlertsForm(false);
  };

  const className = css(buttonStyle);

  return shouldOpenAsModal || isModal ? (
    <>
      <AlertsButton
        className={className}
        author={author}
        onToggle={toggleAuthorAlertsForm}
        forwardedRef={innerRef}
      >
        <IconAlert size={iconSize} miscStyles={iconMiscStyles} />
        <span>
          {' '}
          {theme.alertsI18n.desktopAlertsText}
        </span>
      </AlertsButton>
      <A11yDialog
        identifier="authorAlert"
        appendTo="modalsRootAuthorAlert"
        elementToHide="pageRoot"
        isVisible={isShowAuthorAlertsForm}
        overlayBgColor="rgba(255, 255, 255, 0.9)"
        closeOnOutsideClick
        onClose={closeAuthorAlertsForm}
        styledModal
        render={({ isVisible, handleClose, }) => (
          <div className={css({ textAlign: 'center', })}>
            <div className={css({
              overflow: 'hidden',
              left: 0,
              right: 0,
              backgroundColor: theme.color('primary', '-5'),
              zIndex: theme.getZIndex('above'),
              ...(isModal ? theme.mq({ until: 's', }, { width: '52rem', }) : {}),
            })}
            >
              <AuthorNotificationsRegistration
                origin={origin}
                author={author}
                platform={platform}
                biAction={biAction}
                onToggle={() => handleClose()}
                isBlog={isBlog}
              />
            </div>
          </div>
        )}
      />
    </>
  ) : (
    <AlertsButton
      className={className}
      author={author}
      onToggle={onToggle}
      forwardedRef={innerRef}
    >
      <IconAlert
        size={iconSize}
        miscStyles={iconMiscStyles}
      />
      <span>
        {' '}
        {theme.alertsI18n.mobileAlertsText}
      </span>
    </AlertsButton>
  );
}

const mqType = {
  until: PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]),
  from: PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]),
};
AuthorAlert.propTypes = {
  ...alertButtonPropTypes,
  platform: PropTypes.string,
  biAction: PropTypes.func,
  isModal: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.elementType), }),
  ]),
  /**
   * Indicates on which breakpoints alerts registration dialog should open as a modal.
   */
  modalVariationMq: PropTypes.oneOf(
    PropTypes.arrayOf(PropTypes.shape(mqType)),
    PropTypes.shape(mqType)
  ),
  miscStyles: stylesPropType,
  iconMiscStyles: stylesPropType,
  iconSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.shape({ ...mqType, value: PropTypes.number, }),
    ),
  ]),
  isBlog: PropTypes.bool.isRequired,
};

AuthorAlert.defaultProps = {
  ...alertsButtonDefaultProps,
  platform: null,
  innerRef: null,
  biAction: null,
  isModal: false,
  modalVariationMq: null,
  miscStyles: null,
  iconMiscStyles: null,
  iconSize: [ { until: 's', value: 3, }, { from: 's', value: 2.5, }, ],
};

export default React.forwardRef((props, ref) => <AuthorAlert innerRef={ref} {...props} />);
