/* global fetch */
import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { useMutation, } from '@apollo/react-hooks';
import config from 'config';
import isEmail from 'validator/lib/isEmail';
import TextInput from '../TextInput/TextInput';
import Button from '../Button/Button';
import Form from '../Form/Form';
import UserDispenser from '../User/UserDispenser';
import IconClose from '../Icon/icons/IconClose';
import userAlertsRegistrationMutation from './userAlertsRegistrationMutation.graphql';
import tagAlertsRegistrationMutation from './tagAlertsRegistrationMutation.graphql';

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;
const site = siteNumber === 10 || siteNumber === 20
  ? 'tm'
  : siteNumber === 85
    ? 'hdc'
    : 'htz';

function validate({ email, theme, }) {
  const errors = [];

  if (!email) {
    errors.push({
      name: 'email',
      order: 1,
      errorText: theme.serviceByMailI18n.inpEmailErrorRequired,
    });
  }
  else if (!isEmail(email)) {
    errors.push({
      name: 'email',
      order: 1,
      errorText: theme.serviceByMailI18n.inpEmailErrorInvalid,
    });
  }

  return errors;
}

function getValueByType(type, values) {
  switch (type) {
    case 'user':
      return values.user;
    case 'tag':
      return values.tag;
    default:
      return values.user;
  }
}

export default function ServiceByMailRegistration({
  onRegistrationError,
  onRegistrationSuccess,
  title,
  resourceType,
  resourceId,
  resourceName,
  origin,
  onCancel,
  type,
}) {
  const [ isBusy, setIsBusy, ] = React.useState(false);
  const { css, theme, } = useFela();

  const mutation = getValueByType(type, {
    user: userAlertsRegistrationMutation,
    tag: tagAlertsRegistrationMutation,
  });


  const [ register, ] = useMutation(mutation);

  function handleSubmit(values) {
    setIsBusy(true);

    const { email, } = values;

    const input = {
      ...(getValueByType(type, {
        user: {
          resourceType,
          resourceId,
          resourceName,
          origin,
          email,
          site,
        },
        tag: {
          resourceId,
          email,
          site,
        },
      })), };

    register({ variables: { input, }, })
      .then(res => {
        const responseFiend = getValueByType(type, { user: 'userAlertsRegistration', tag: 'tagAlertsRegistration', });
        const { status, } = res.data[responseFiend];
        if ([ 'success', 'exist', 'pending', ].includes(status)) {
          onRegistrationSuccess && onRegistrationSuccess({ status, email, });
          setIsBusy(false);
        }
        else throw Error('failed to register for user alerts');
      })
      .catch(data => {
        setIsBusy(false);

        if (onRegistrationError) {
          onRegistrationError(data);
        }
      });
  }

  const isWriter = resourceType ? resourceType === 'writer' : false;

  return (
    <UserDispenser
      render={({ isLoggedIn, user, }) => (
        <div
          data-test="serviceByMailRegistrationDialog"
          className={css({
            backgroundColor: theme.color('alerts', 'bg'),
            padding: '2rem',
            position: 'relative',
            textAlign: 'start',
          })}
        >
          <button
            onClick={() => {
              onCancel();
            }}
            type="button"
            className={css({
              top: '1rem',
              end: '1rem',
              position: 'absolute',
            })}
          >
            <IconClose size={3} />
          </button>
          {title ? (
            <p
              className={css({
                fontWeight: 'bold',
                extend: [ theme.type(-1), theme.mq({ until: 'l', }, { paddingInlineEnd: '7rem', }), ],
              })}
            >
              {title}
            </p>
          ) : (
            ''
          )}
          <Form
            className={css({
              marginTop: '3rem',
              display: 'flex',
              flexDirection: 'row',
              extend: [
                theme.mq({ until: 's', }, { flexDirection: 'column', alignItems: 'center', }),
                theme.mq({ from: 's', }, { alignItems: isWriter ? 'flex-start' : 'end', }),
              ],
            })}
            onSubmit={handleSubmit}
            initialValues={{ email: isLoggedIn ? user.email : null, }}
            validate={({ email, }) => validate({ email, theme, })}
            // eslint-disable-next-line no-shadow
            render={({ getInputProps, handleSubmit, clearForm, }) => (
              <>
                <TextInput
                  {...getInputProps({
                    name: 'email',
                    label: theme.serviceByMailI18n.inpEmailLabelText,
                    type: 'email',
                    isError: false,
                    placeHolder: theme.serviceByMailI18n.inpEmailLabelText,
                    noteText: theme.serviceByMailI18n.inpEmailNoteText,
                    variant: theme.alertsStyle.inputVariant,
                    requiredText: {
                      isSup: true,
                      long: 'required',
                      short: '*',
                    },
                    miscStyles: {
                      type: -2,
                      fontWeight: 'bold',
                      width: [ { from: 'l', value: '64rem', }, ],
                      color: 'inherit',
                      ':hover': {
                        color: 'inherit',
                      },
                    },
                    wrapperStyle: {
                      extend: [ theme.mq({ until: 'l', }, { width: '100%', }), ],
                    },
                  })}
                />
                <Button
                  attrs={{ 'data-test': 'serviceByMailRegistrationDialogSendButton', }}
                  variant="neutral"
                  onClick={handleSubmit}
                  isBusy={isBusy}
                  miscStyles={{
                    marginInlineStart: [ { from: 's', value: '1rem', }, ],
                    type: -2,
                    marginTop: [ { until: 's', value: '2rem', }, ],
                  }}
                >
                  {theme.serviceByMailI18n.btnSubmitText}
                </Button>
              </>
            )}
          />
        </div>
      )}
    />
  );
}

ServiceByMailRegistration.propTypes = {
  /**
   * The Title for the form
   * */
  title: PropTypes.string,
  /**
   * The user alerts type
   * */
  resourceType: PropTypes.oneOf([ 'writer', 'blog', 'tag', 'newsletter', ]).isRequired,
  /**
   * According to resource type:
   * "writer": the author's ID
   * "newsletter": tagNumber
   * "blog": ?
   * "tag": ?
   * */
  resourceId: PropTypes.string.isRequired,
  /**
   * According to resource type:
   * "writer": the author's name
   * "newsletter": segmentName
   * "blog": ?
   * "tag": ?
   * */
  resourceName: PropTypes.string.isRequired,
  /**
   * Callback to execute when registration successfully completed
   * */
  onRegistrationSuccess: PropTypes.func,
  /**
   * Callback to execute when registration fails
   * */
  onRegistrationError: PropTypes.func,
  /**
   * Callback to execute when cancel button is pressed, If omitted, the Cancel button wont appear
   * */
  onCancel: PropTypes.func,
  origin: PropTypes.string,
  type: PropTypes.oneOf([ 'user', 'tag', ]),
};

ServiceByMailRegistration.defaultProps = {
  title: null,
  onRegistrationSuccess: null,
  onRegistrationError: null,
  onCancel: null,
  origin: null,
  type: 'user',
};
