import * as React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

const alertButtonPropTypes = {
  /**
   * The author object which the user can register for his alerts.
   */
  author: PropTypes.object.isRequired,
  /**
   * The tag object which the user can register for his alerts.
   */
  tag: PropTypes.object.isRequired,
  /**
   * A function to execute when button is toggled.
   * (Use to show/hide the registration form)
   */
  onToggle: PropTypes.func,
};

const alertsButtonDefaultProps = {
  onToggle: null,
};


AlertsButton.defaultProps = {
  onToggle: null,
  className: null,
};

export default function AlertsButton({
  author,
  children,
  className,
  forwardedRef,
  onToggle,
  tag,
}) {
  const [ isExpanded, setIsExpanded, ] = React.useState(false);
  const mountRef = React.useRef(isExpanded);
  const { theme, } = useFela();

  React.useEffect(
    () => {
      mountRef.current !== isExpanded && onToggle && onToggle(isExpanded);
      // Prevents infinite loop created by an upper element that changes the onToggle prop
      mountRef.current = isExpanded;
    },
    [ isExpanded, onToggle, ]
  );

  return (
    <button
      type="button"
      ref={forwardedRef}
      className={className}
      aria-expanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      aria-describedby="alerts_btn_description"
      data-test={tag ? 'tagAlertButton' : 'writerAlertButton'}
    >
      <VisuallyHidden id="alerts_btn_description">
        {`${theme.alertsI18n.alertsTextA11y} ${(author || tag).contentName}`}
      </VisuallyHidden>
      {children}
    </button>
  );
}

AlertsButton.propTypes = {
  ...alertButtonPropTypes,
  /**
   * CSS class names provided by Fela
   */
  className: PropTypes.string,
};

AlertsButton.defaultProps = {
  ...alertsButtonDefaultProps,
  className: null,
};

export { alertButtonPropTypes, alertsButtonDefaultProps, };
